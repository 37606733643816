import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { data } from "./data";
import Card from "@material-ui/core/Card";
import HouseIcon from '@material-ui/icons/House';

const useStyles = makeStyles({
  raumcontainer: {
    border: "solid black 1px",
  },
  part1: { padding: 20, margin: 5 },
  card: { backgroundColor: "E5E5E5", padding: 10, margin: 5, width: 400, height: 240},
});
export default function Raumungen() {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.raumcontainer}
      alignItems="center"
      justify="center"
      direction="column"
    >
      <Grid item xs>
        <Grid container className={classes.part1}>
          <Typography variant="h1"> Wir Räumen für Sie: </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='row' alignItems="center" justify="center">
          {data.map((data, key) => {
            return (
                <Grid item>
              <Card className={classes.card} key={key}>
                
                  <Grid container justify='center' alignItems='center' direction='column'>
                  <HouseIcon style={{fontSize: 50}}/>
                <Typography variant="h6">{data.name}</Typography>
                <Typography variant='body1'>{data.text}</Typography>
</Grid>
              </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
