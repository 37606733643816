import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Start from "./Start/Start";
import Aboutus from "./Aboutus/Aboutus";
import Angebot from "./Angebot/Angebot";
import Kontakt from "./Kontakt/Kontakt";
import CssBaseline from "@material-ui/core/CssBaseline";
import Raumungen from './Raumungen/Raumungen'

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
      <Header />

        <Switch>
          <Route exact path="/ueber-uns">
            <Aboutus />
          </Route>
          <Route exact path="/">
            <Start />
          </Route>
          <Route exact path='/Räumungen'>
          <Raumungen/>
          </Route>
          <Route exact path='/Angebote'>
            <Angebot/>
          </Route>
          <Route path='/Kontakt'>
            <Kontakt/>
                      </Route>
        </Switch>{" "}
    <Footer/>
    </Router>
    </React.Fragment>
  );
}

export default App;
