import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
const position = [47.50744, 8.72591];

export default function LocateMaps() {
  return (
    <MapContainer
      center={position}
      zoom={14}
      scrollWheelZoom={false}
      style={{ height: "60vh", maxWidth: "100vw" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          Blitzreinigung Winterthur <br />
          Brunngasse 41 <br />
          8400 Winterthur <br />
        </Popup>
      </Marker>
    </MapContainer>
  );
}
