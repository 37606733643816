import React from "react";
import { createUseStyles } from "react-jss";
import Grid from "@material-ui/core/Grid";
import bild7 from './img/bild7.jpg'
import FlashOnIcon from "@material-ui/icons/FlashOn";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors'
import Cards from './Cards'
import background1 from './img/background1.jpg'
const minWidth = 750
const THEME = createMuiTheme({
    palette: { primary: red } ,
  typography: {
   
    "letterSpacing": 0.32,
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    h6: {
      "fontWeight": 600,
    },
    h4:{
        'fontWeight': 300,
        'fontSize' : 33
    },
    h3:{'fontSize': 40}
  },
});

const useStyles = createUseStyles({
  container: {
   
   
  },
  firstpart: {
    backgroundImage: `url(${background1})`,
    height: "100vh",
    backgroundAttachment: "fixed",
    backgroundSize:'cover',
    backgroundRepeat: 'no-repeat'
    
  },
  titeltext: {
   
    color: "white",
    backgroundColor: "rgba(46, 49, 49, 0.56)",
    maxWidth: "50vw",
    height: "100vh",
    padding: 25
    
  },
  infotitel: {
   marginBottom: 52,
    
  },
  infotext: {
    fontSize: "16px",
  },
  secondpart: {
    height: "75vh", 
    
  },
  clicktitel: {
    padding: 10,
 
  },
  clicktext: {
    padding: 30,
 
    
  },
 thirdpart: {
    color: "white",
    backgroundImage: `url(${bild7})`,
    height: "100vh",
    backgroundAttachment: "fixed",
    backgroundSize:'cover',
    backgroundRepeat: 'no-repeat',
    padding: 15,
    
    
  },
  abschluss:{
      paddingBottom: 45
  },
  button:{
    fontSize: 36,
    height: '70px',
    width: '210px'
   
  },a: {
    textDecoration: "none",
    color: 'red',
    "&:link": {
      textDecoration: "none",
    },
    "&:visited": {
      textDecoration: "none",
    },},
  [`@media (max-width: ${minWidth}px)`]: {
    
     firstpart:{
       background: 'blue',
       maxWidth: '100vw'
     },
    button:{
      display: 'none'
    },
    secondpart:{
      height: '100%',
      margin: 25,
      maxWidth: '100vw'
    },
    titeltext:{
      maxWidth: '100%'
    },
    a: {
      textDecoration: "none",
      color: "red",
      "&:link": {
        textDecoration: "none",
      },
      "&:visited": {
        textDecoration: "none",
      },
    firstpart:{
      maxWidth: '100vw'
    }}
  }
});

export default function Start() {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={THEME}>
    <Grid container direction="column" className={classes.container}>
      <Grid item >
        <Grid container className={classes.firstpart}>
          <Grid item>
            <Grid
              container
              className={classes.titeltext}
              alignItems='flex-start'
              direction="column"
              justify='center'            >
              <Typography variant='h1' className={classes.infotitel}>Blitz-Reinigung</Typography>
              <Typography variant='h4' className={classes.infotext}>
                Reinigungsunternehmen in Winterthur:</Typography>
                <Typography variant='h4' className={classes.infotext}> Wir übernehmen Reinigungen
                für Büros, Praxen, Geschäfte und Privatpersonen</Typography>
              
              
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            className={classes.secondpart}
            alignItems="center"
            direction="column"
            justify="center"
          >
            <Grid item>
              <FlashOnIcon style={{ color: "red", fontSize:90 }} />
            </Grid>
            <Grid item>
              {" "}
              <Typography variant='h3' className={classes.clicktitel}>
                Herzlich willkommen bei Blitz-Reinigung
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h4' className={classes.clicktext}>
                Es freut uns, dass Sie den Weg auf unsere Webseite gefunden
                haben. Wir reinigen Fenster, Teppiche, Wohnungen, Treppenhäuser,
                ganze Geschäftsräume, Neubauten und übernehmen auch ganze
                Hauswartungen.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h4' className={classes.clicktext}>
                Möchten Sie uns als Reinigungsfirma beauftragen? Wir freuen uns
                auf Ihre <a href='/kontakt' className={classes.a}>Kontaktaufnahme</a>.
              </Typography>
            </Grid>
            <Grid item>
              <a href='/Kontakt'><Button variant="contained" color="primary" className={classes.button} >
               Reinigungsanfrage
              </Button></a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
          <Grid container className={classes.thirdpart} alignItems='flex-start'
              direction="column"
              justify='center' >

<Typography variant='h1' className={classes.abschluss}>Blitzschnell.

</Typography>
<Typography variant='h1' className={classes.abschluss}>Sorgfältig.</Typography>
<Typography variant='h1' className={classes.abschluss}>Sauber.</Typography>
          </Grid>
      </Grid>
      <Grid item>
        <Cards/>
      </Grid>
    </Grid>
    </MuiThemeProvider>
  );
}
