import React from "react";
import { createUseStyles } from "react-jss";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import background2 from "../Start/img/background2.jpg";
import bild7 from '../Start/img/bild7.jpg'
const minWidth = 750;
const muiTheme = createMuiTheme({
  overrides: {
    typography: {
      letterSpacing: 0.32,
      useNextVariants: true,
      suppressDeprecationWarnings: true,
      h6: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 500,
        fontSize: 35,
      },
      body1: { fontSize: 500 },
    },

    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "red",
          "&:hover": {
            backgroundColor: "orange",
          },
        },
      },
      button: {
        marginBottom: 4,
        backgroundColor: "#0091D1",
        "&:hover": {
          backgroundColor: "#166197",
        },
      },
    },
  },
});
const useStyles = createUseStyles({
  container: {},
  titelbildtext:{
    maxWidth: '100vw'
  },
  firstpart: {
    backgroundImage: `url(${background2})`,
    height: "60vh",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    minWidth: "100vw",
    color: "white",
    maxWidth: "100vw",
    paddingLeft: 20
    
  },
  titel2: {
    paddingBottom: 40,
  },
  schrift1: { paddingBottom: 30 },
  sidepart1: {
    maxWidth: "60vw",
    margin: 10,
    padding: 10,
    minHeight: '60vw'
  },
  sidepart2:{
    minHeight: '60vw'
  },
  list: { color: "white" },
  icon: {
    fontSize: 55,
    fontWeight: 700,
    marginLeft: 15,
    marginRight: 25,
  },
  underbody1: {
    paddingBottom: 40,
    paddingTop: 10,
  },
  thirdpart: {
    color: "white",
    backgroundImage: `url(${bild7})`,
    height: "60vh",
    backgroundAttachment: "fixed",
    backgroundSize:'cover',
    backgroundRepeat: 'no-repeat',
    padding: 15,
    
    
  },
  [`@media (max-width: ${minWidth}px)`]: {
    sidepart1: { height: "100%", maxWidth: "100vw", margin: 0, padding: 0 },
  },
});

export default function Aboutus() {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={muiTheme}>
      <Grid
        container
        className={classes.container}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Grid container className={classes.firstpart} alignItems='flex-end'>
            <Typography variant="h1" className={classes.titelbildtext}>Blitzreinigung-Winterthur</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            className={classes.sidepart1}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography variant="h3" className={classes.titel2}>
              Reinigungsfirma für Umzüge, Büros und Geschäfte in Winterthur
            </Typography>
            <Typography variant="h5" className={classes.schrift1}>
              Seit der Gründung von Blitz-Reinigung im Jahr 2004 sorgen wir für
              grenzenlose Sauberkeit – schnell, gründlich und zuverlässig. Unser
              Familienbetrieb hat seinen Sitz in Winterthur und beschäftigt
              sieben erfahrene Reinigungsprofis. Wir sind in der Branche bestens
              vernetzt und können für umfangreiche Aufträge auf zuverlässige und
              flexible Aushilfen zählen.
            </Typography>
            <Typography variant="h5" className={classes.schrift1}>
              Wir sind spezialisiert auf Unterhalts-, Immobilien-, Neubau- und
              Umzugsreinigungen, vor allem im Grossraum Winterthur. Zusätzlich
              übernehmen wir die Hauswartung von Liegenschaften. Alle unsere
              Services sind als Einzelauftrag oder im Abo zu sehr attraktiven
              Konditionen erhältlich.
            </Typography>
            <Typography variant="h5">Inhaber Blitz-Reinigung</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            className={classes.sidepart2}
            direction="column"
            alignItems="center"
            justify="center"
          >
            {" "}
            <Typography variant="h4">Unsere Services</Typography>
            <Typography variant="h5" className={classes.underbody1}>
              Folgende Services sind als Einzelauftrag oder im Abo erhältlich:
            </Typography>
            <Grid item>
              <List component="nav" className={classes.list}>
                <ListItem button>
                  <CheckIcon className={classes.icon} />
                  <Typography variant="h6" className={classes.textrechts}>
                    Fensterreinigung
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem button>
                  <CheckIcon className={classes.icon} />
                  <Typography variant="h6" className={classes.textrechts}>
                    Unterhalts-Büroreinigung
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem button>
                  <CheckIcon className={classes.icon} />
                  <Typography variant="h6" className={classes.textrechts}>
                    Umzugsreinigung mit Abgabegarantie
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem button>
                  <CheckIcon className={classes.icon} />
                  <Typography variant="h6" className={classes.textrechts}>
                    Bauabschlussreinigung
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem button>
                  <CheckIcon className={classes.icon} />
                  <Typography variant="h6" className={classes.textrechts}>
                    Teppich-/ Bodenreinigung
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem button>
                  <CheckIcon className={classes.icon} />
                  <Typography variant="h6" className={classes.textrechts}>
                    Winterdienst / Schneeräumung
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem button>
                  <CheckIcon className={classes.icon} />
                  <Typography variant="h6" className={classes.textrechts}>
                    Frühlingsputz / Herbstputz
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem button>
                  <CheckIcon className={classes.icon} />
                  <Typography variant="h6" className={classes.textrechts}>
                    Hauswartung
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.thirdpart}
          alignItems="flex-start"
          direction="column"
          justify="center"
        >
         
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
}
