import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createUseStyles } from "react-jss";
import Button from "@material-ui/core/Button";
import bild3 from "./img/bild3.jpg";
import logo from '../Header/img/blitz_logo.png'
const minWidth = 750
const useStyles = createUseStyles({
  container: {
    display: 'block',
    height: '110vh',
    width: "100vw",
  },
  cardbild: {
    width: "555px",
    height: "415px",
  },
  card: {
      height: '100vh',
    margin: 30,
    maxWidth: 555
  },
  body1:{
      padding: 10,
      paddingBottom: 25
  },
  h5:{
      padding: 15,
      paddingBottom: 35
  },a: {
    textDecoration: "none",
    color: 'red',
    "&:link": {
      textDecoration: "none",
    },
    "&:visited": {
      textDecoration: "none",
    },},
  buttona:{
      padding: 15,
      margin: 10
  },[`@media (max-width: ${minWidth}px)`]: {container:{height: '150vh', maxWidth: '100vw'}, cardbild:{display: 'none'}}
});

export default function Cards() {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.container}
      alignItems="center"
      justify="space-evenly"
      direction='row'
    >
      <Grid item xs={12} md={6}>
        
          <Grid container direction="column" justify='space-evenly' alignItems="center" className={classes.card}>
            <img src={bild3} alt="bild" className={classes.cardbild} />
            <Typography variant="h5" className={classes.h5}>Reinigungsangebot</Typography>
            <Typography variant="body1" className={classes.body1}>
              Wir übernehmen Reinigungsaufträge für Büros, Praxen, Geschäfte und
              auch Privatpersonen – einzeln oder im Abo.
            </Typography>
          <a href='/Angebote' className={classes.a}> <Button color='primary' variant='outlined' className={classes.buttona}>Mehr..</Button></a> 
          </Grid>
       
      </Grid>
      <Grid item xs={12} md={6}>
       
        <Grid container direction="column" alignItems="center"  justify='space-evenly' className={classes.card}>
            <img src={logo} alt="bild" className={classes.cardbild} />
            <Typography variant="h5" className={classes.h5}>Blitz-Reinigung</Typography>
            <Typography variant="body1" className={classes.body1}>
            Seit über 10 Jahren sorgen wir für Frische und Sauberkeit an Ihrem Arbeitsplatz oder bei Ihnen zuhause.
            </Typography>
          <a href='/ueber-uns' className={classes.a}>  <Button color='primary' variant='outlined' className={classes.buttona}>Mehr..</Button></a>
          </Grid>
        
      </Grid>
    </Grid>
  );
}
