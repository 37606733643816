import React from "react";
import { createUseStyles } from "react-jss";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import bild8 from "../Start/img/bild8.jpg";
import schnee from "../Start/img/schnee.jpg";
import fruhling from "../Start/img/fruhling.jpg";
import bild2 from "../Start/img/bild2.jpg";
import umzug from "../Start/img/umzug.jpg";
import bild1 from "../Start/img/bild1.jpg";
import bauab from "../Start/img/bauab.jpg";
import bild7 from "../Start/img/bild7.jpg";
import background1 from "../Start/img/background1.jpg";

const minWidth = 750;
const THEME = createMuiTheme({
  palette: { primary: red },
  typography: {
    letterSpacing: 0.32,
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    h6: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 300,
      fontSize: 28,
    },
    h3: { fontSize: 35 },
  },
});
const useStyles = createUseStyles({
  firstpart: {
    backgroundImage: `url(${background1})`,
    height: "60vh",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    maxWidth: "100vw",
  },
  secondpart: {
    height: "75vh",
    paddingLeft: 100,
    paddingRight: 100,

    maxWidth: "100vw",
  },
  clicktitel: {
    margin: 10,
    padding: 20,
  },
  clicktext: {
    margin: 10,
    padding: 20,
  },
  thirdpart: {
    color: "white",
    backgroundImage: `url(${bild7})`,
    height: "60vh",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: 10,
  },
  abschluss: {
    paddingBottom: 45,
  },

  card: {
    margin: 5,
    padding: 10,
  },
  titeltext: { color: "white", width: "100vw", height: "100%" },
  bild: {
    height: "200px",
    width: "300px",
  },
  a: {
    textDecoration: "none",
    color: "red",
    "&:link": {
      textDecoration: "none",
    },
    "&:visited": {
      textDecoration: "none",
    },
  },
  titel: {
    padding: 10,
    margin: 10,
  },
  text: {
    margin: 15,
    padding: 15,
    width: "15vw",
  },
  button: {
    paddingTop: 40,
    paddingLeft: 10,
    maxWidth: "200px",
    maxHeight: "80px",
    minWidth: "200px",
    minHeight: "80px",
  },
  [`@media (max-width: ${minWidth}px)`]: {
    secondpart: { height: "100%", margin: 5, padding: 5, minWidth:'100vw'},
    text: { margin: 10, padding: 10, width: "100%" },
    titel: { margin: 10,},
    firstpart:{display: 'none'},
    abschluss: { display: "none" },
  },
});

export default function Angebot() {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={THEME}>
      <Grid container direction="column" justify="center">
        <Grid item xs={12}>
          <Grid container className={classes.firstpart}>
            <Grid item>
              <Grid
                container
                className={classes.titeltext}
                alignItems="flex-end"
                direction="column"
                justify="flex-end"
              >
                <Typography variant="h1" className={classes.infotitel}>
                  Reinigungsangebot
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            className={classes.secondpart}
            justify="center"
            alignItems="flex-start"
            direction="column"
          >
            <Grid item>
              {" "}
              <Typography variant="h3" className={classes.clicktitel}>
                Reinigungsunternehmen – schnell, zuverlässig und sauber
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" className={classes.clicktext}>
                Geniessen Sie Ihr Leben in einer sauberen Umgebung, ob zuhause
                oder bei der Arbeit. Wir übernehmen die professionelle Reinigung
                von Privatwohnungen, Büroräumen und öffentlichen Gebäuden – vom
                Abstauben bis zum Fensterputzen, von der Möbelpflege bis zur
                Teppichreinigung.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" className={classes.clicktext}>
                Auf unsere Umzugsreinigung bekommen Sie stets eine
                Abgabegarantie. Neubauten reinigen wir zuverlässig, gründlich
                und blitzschnell. Und auf Wunsch übernehmen wir auch die
                Hauswartung Ihrer Liegenschaft.
              </Typography>
            </Grid>
            <Grid item className={classes.button}>
              <a className={classes.a} href="/Kontakt">
                {" "}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Reinigungsanfrage
                </Button>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="center">
            <Grid item sm={12} md={3}>
              <Grid
                container
                className={classes.card}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img src={bild8} alt="bild8" className={classes.bild} />
                <Typography variant="h3" className={classes.titel}>
                  Fensterreinigung
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  Endlich wieder klare Sicht! Wir reinigen Ihre Fenster
                  professionell und sorgfältig. Vor allem im Frühling ist es
                  schön, wenn wir dafür sorgen dürfen, dass endlich wieder Licht
                  in Ihre vier Wände kommt.
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={12} md={3}>
              <Grid
                container
                className={classes.card}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img src={bild2} alt="bild8" className={classes.bild} />
                <Typography variant="h3" className={classes.titel}>
                  Büroreinigung
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  Mit einer regelmässigen Unterhaltsreinigung können Sie Ihnen
                  und Ihren Mitarbeitern einen hygienischen Arbeitsplatz
                  garantieren. Nach Vereinbarung kommen wir jeweils immer am
                  gleichen Tag zur gleichen Uhrzeit.
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={12} md={3}>
              <Grid
                container
                className={classes.card}
                direction="column"
                justify="space-evenly"
                alignItems="center"
              >
                <img src={umzug} alt="bild8" className={classes.bild} />
                <Typography variant="h3" className={classes.titel}>
                  Umzugsreinigung
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  Oh, oh - steht ein Umzug bevor? Haben Sie noch viele
                  Pendenzen, die erledigt werden müssen? Eine davon würden wir
                  Ihnen liebend gerne abnehmen und zwar die Endreinigung Ihrer
                  aktuellen Wohnung, Haus oder der Büroräumlichkeiten.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid
                container
                className={classes.card}
                direction="column"
                justify="space-evenly"
                alignItems="center"
              >
                <img src={bauab} alt="bild8" className={classes.bild} />
                <Typography variant="h3" className={classes.titel}>
                  Bauabschluss
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  Nach Neu- oder Umbauten muss noch ein letzter Feinschliff
                  gemacht werden und zwar so sorgfältig, dass man schon bald
                  (wieder) einziehen kann oder Sie die Liegenschaft zur
                  Besichtigung ausschreiben können. Gerne übernehmen wir die
                  Bauabschlussreinigung für Sie.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid
                container
                className={classes.card}
                direction="column"
                justify="space-evenly"
                alignItems="center"
              >
                <img src={bild1} alt="bild8" className={classes.bild} />
                <Typography variant="h3" className={classes.titel}>
                  Teppich- {<br />}und Bodenreinigung
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  Wir reinigen Teppichböden oder einzelne Teppich so, damit alle
                  Sie befreit werden von allfälligen Flecken und Unreinheiten.
                  Selbstverständlich reinigen wir auch mit Hochdruckreiniger
                  Ihren Terrassen- oder Balkonboden.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid
                container
                className={classes.card}
                direction="column"
                justify="space-evenly"
                alignItems="center"
              >
                <img src={schnee} alt="bild8" className={classes.bild} />
                <Typography variant="h3" className={classes.titel}>
                  Winterdienst / {<br />} Schneeräumung
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  Schneeflöcken, Schneeflöcken - das mögen wir in der Schweiz,
                  aber wir möchten ohne Risiko morgens aus unserem Haus oder am
                  Abend aus dem Geschäft laufen. Kontaktieren Sie uns und wir
                  räumen regelmässig den Schnee vor Ihrer Türe.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid
                container
                className={classes.card}
                direction="column"
                justify="space-evenly"
                alignItems="center"
              >
                <img src={fruhling} alt="bild8" className={classes.bild} />
                <Typography variant="h3" className={classes.titel}>
                  Frühlingsputz / {<br />} Herbstputz
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  Wenn es wieder wärmer wird, die Bäume grün werden, die Blumen
                  farbig werden, dann ist die Zeit da, wo wir alle wieder Lust
                  haben, dass zu Hause alles sauber ist und dazu gehört ein
                  sogenannter Frühlings- oder auch Herbstputz.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid
                container
                className={classes.card}
                direction="column"
                justify="space-evenly"
                alignItems="center"
              >
                <img src={bild8} alt="bild8" className={classes.bild} />
                <Typography variant="h3" className={classes.titel}>
                  Hauswartung
                </Typography>
                <Typography variant="body1" className={classes.text}>
                  Neben unserem Reinigungsangebot bieten wir eine zuverlässige
                  Hauswartung für Liegenschaften an. Nehmen Sie mit uns Kontakt
                  auf, gerne beraten wir Sie und kommen bei Ihnen vorbei, um uns
                  vorzustellen.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.thirdpart}
          alignItems="flex-start"
          direction="column"
          justify="center"
        >
          <Typography variant="h1" className={classes.abschluss}>
            Reinigung?
          </Typography>
          <Typography variant="h1" className={classes.abschluss}>
            Hauswartung?.
          </Typography>
          <Typography variant="h3" className={classes.abschluss}>
            Wir bieten beides!
          </Typography>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
}
