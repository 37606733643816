import React from 'react'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
const useStyles = makeStyles({
    container:{
        position: 'static',
        bottom: '0',
        height: '25vh',
        width: '100%',
        backgroundColor: '#0091D1'
    },

    p:{
        color: 'white',
        padding: 0,
        margin: 0,
        fontSize: '17px'
    }
})
export default function Footer(){
    const classes = useStyles()
    return(

        <Grid container className={classes.container}
        direction="row"
        justify="space-around"
        alignItems='center'>
            <Grid item>
                <Grid container alignItems='center' direction='column'>
                <HomeIcon style={{color: 'white', paddingBottom: 3}}/>
                <p className={classes.p}>Blitz-Reinigung</p>         
                 <p className={classes.p}> Brunngasse 41  </p>     
                 <p className={classes.p}> 8400 Winterthur</p>
            </Grid></Grid>
            <Grid item><Grid container alignItems='center' direction='column'>
                    <PhoneIcon style={{color: 'white', paddingBottom: 3}}/>
                 <p className={classes.p}>052 202 66 57</p>         
                 <p className={classes.p}> 079 421 62 14  </p>     
                 
    
         </Grid>
            </Grid>
        </Grid>
    )
}