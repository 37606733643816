import React from 'react'
import Form from './Form.js'
import LocateMaps from './LocateMap.js'
export default function Kontakt(){
    return(
        <div>
            <Form/>
            <LocateMaps/>
            
        </div>
    )
}