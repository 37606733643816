export const data = [
  {
    id: 1,
    name: "Haus und Wohnungsräumungen",
    text:
      "Wir Räumen Ihr Haus für Sie. Oft bleiben bei Umzügen Dinge zurück, die nicht mehr benötigt werden, oder ganze Haushälte müssen geräumt werden. Wir helfen Ihnen unkompliziert und Schnell.",
    icon: "HouseIcon"},
  {
    id: 2,
    name: "Lagerräumung ",
    text: 'Gerne schaffen wir neuen Platz für Sie, Private und Gewerbekunden unterstützen wir bei Räumungen von Lagern bzw. Lagerhallen'
  },{
      id: 3,
      name: 'Messiewohnung / Haus',
      text: 'Wir übernehmen auch die Räumung, Entsorgung und Reinigung von Messiewohnungen und Häusern, Diskret und unkompliziert erledigen unsere Profis mit jahrelanger erfahrung diese schwierige '
  }
];
