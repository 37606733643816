import React from "react";
import { createUseStyles } from "react-jss";
import Headroom from "react-headroom";
import Grid from "@material-ui/core/Grid";
import logo from "./img/blitz_logo.png";
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

const minWidth = 600;
const useStyles = createUseStyles({
  headercontainer: {
    
  
   maxWidth: '100vw',
    height: "25vh",
  
   
  },
  a: {
    textDecoration: "none",
    color: "black",
    "&:link": {
      textDecoration: "none",
    },
    "&:visited": {
      textDecoration: "none",
    },
  },
  drawer: {
    width: "30vw",
  },

  linkcontainer: {
    height: "5vh",
    width: "50vw",
  },
  link: {
    margin: 10,
    padding: 5,
    paddingLeft: 20,
    fontSize: 40,
    fontWeight: 200
  },
  linkButton: {
    display: "none",
  },
  
  logo: {
    marginRight: 10,
    width: "400px",
    height: "auto",
    
  },
  [`@media (max-width: ${minWidth}px)`]: {
    link: {
      display: "none",
      borderLeft: "none",
    },
    headercontainer: {
      borderBottom: "solid black 1px",
      maxWidth: '100vw'
    },
    "&:hover": {
      borderBottom: "solid black 1px",
    },
    linkButton: {

      display: "inline-block",

    },
    "&:active": {
      backgroundColor: "grey",
    },
    logo:{width: '150px'}
  },
});

export default function Header() {
  const classes = useStyles();
  const [left, setLeft] = React.useState(false);

  const toggleDrawer = () => (event) => {
    setLeft(!left);
    console.log("oh dear");
  };
  return (
    <React.Fragment>
      <Headroom>
        <Grid
          container
          className={classes.headercontainer}
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Box className={classes.linkButton} onClick={toggleDrawer()}>
          <IconButton iconstyle={{height: 150, width: 150, marginLeft: 50}} > <MenuIcon style={{fontSize: 100, marginLeft: 50}} /></IconButton> 
          </Box>{" "}
          <a href="/" className={classes.a}>
            <img src={logo} alt="logo" className={classes.logo} />
          </a>
          <Grid item>
            <Grid
              container
              className={classes.linkcontainer}
              direction="row"
              justify="center"
              alignItems="center"
            ><a href="/ueber-uns" className={classes.a}>
            <h4 className={classes.link}>Über Uns</h4>
          </a>
          <a href='/Angebote' className={classes.a}>
          <h4 className={classes.link}>Angebote
          </h4></a>
          <a href='/Kontakt'className={classes.a}>
          <h4 className={classes.link}>Anfrage</h4></a>
          <a href='/Räumungen'className={classes.a}>
          <h4 className={classes.link}>Räumungen</h4></a>
            </Grid>
          </Grid>{" "}
        </Grid>
      </Headroom>
      

    </React.Fragment>
  );
}
