import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Checkbox } from "@material-ui/core";
import emailjs from "emailjs-com";
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles({
  container: { padding:25,
maxWidth: '100vw' },
});

export default function Form() {
  const classes = useStyles();

  const [firmenname, setFirmenname] = useState("");
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [adresse, setAdresse] = useState("");
  const [plzort, setPlzort] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [nachricht, setNachricht] = useState("");
  const [dienstleistungen, setDienstleistungen] = useState({});
  const [objekttyp, setObjekttyp] = useState()
  console.log(dienstleistungen);
  let zahl = 0;
  console.log(vorname, nachname, firmenname);
  const handleClick = async (vorname, nachname, firmenname, adresse, plzort, tel,email, nachricht, dienstleistungen, objekttyp) => {
    
    try {
      var data = {
        Vorname: vorname,
        Nachname: nachname,
        Firmenname: firmenname,
        Reinigungsname: dienstleistungen,
        Objektname: objekttyp,
        message: nachricht,
        Email: email,
        Tel: tel,
        Adresse: adresse,
        PLZORT: plzort
      };

      console.log(data);

      await emailjs.send(
        "blitzreini",
        "template_ycxsi39",
        data,
        "user_i8kybBC19hesQJ4dfHbev"
      );
    window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.container}
      spacing={3}
    >
      <Typography variant="h3">Reingungsanfrage</Typography>
      <Grid item xs={12}>
        {" "}
        <Grid container justify="center">
          <Typography variant="h5">
            Füllen Sie untenstehendes Formular aus, gerne werden wir uns bei
            Ihnen so rasch als möglich melden, um mit Ihnen Termine und weiteres
            Vorgehen zu besprechen.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid
          container
          direction="row"
          justify='flex-start'
        
        >
        
            <TextField
              id="Firmenname"
              label="Firmenname"
              variant="filled"
              value={firmenname}
              onChange={(e) => setFirmenname(e.target.value)}
            />
         
       
            <TextField
              id="Vorname"
              label="Vorname"
              variant="filled"
              value={vorname}
              onChange={(e) => setVorname(e.target.value)}
            />{" "}
        
          
            <TextField
              id="Nachname"
              label="Nachname"
              variant="filled"
              value={nachname}
              onChange={(e) => setNachname(e.target.value)}
            />
        
   
  
        
            <TextField
              id="Adresse"
              label="Adresse"
              variant="filled"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
            />
        
          
            <TextField
              id="PLZort"
              label="PLZ / Ort"
              variant="filled"
              value={plzort}
              onChange={(e) => setPlzort(e.target.value)}/></Grid>
          
     
         
            <TextField
              id="Email"
              label="E-Mail"
              variant="filled"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />  
         
            <TextField
              id="Tel"
              label="Telefonnummer"
              variant="filled"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />{" "}
          
   
          
                <TextField
                 
                  id="filled-textarea"
                  label="Ihre Nachricht"
          
                  multiline
                  variant="filled"
                  value={nachricht}
                  onChange={(e) => setNachricht(e.target.value)}
                />

      </Grid>
      <Grid item xs={12} md={4}>
        <Grid
          container
          direction="row"
          className={classes.container}
        > <Grid item xs={12}>
          <Typography variant="h6" style={{padding: 20}}>Gewünschte Dienstleistungen</Typography>
         </Grid>
            <Grid item>
                <Checkbox
                  label="Endreinigung"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDienstleistungen({
                        ...dienstleistungen,
                        a: "Endreinigung",
                      });
                    } else if (!e.target.checked) {
                      delete dienstleistungen.a;
                    }
                  }}
                />{" "}
                Endreinigung
             </Grid>
             <Grid item>
                <Checkbox
                  label="Baureinigung"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDienstleistungen({
                        ...dienstleistungen,
                        b: "Baureinigung",
                      });
                    } else if (!e.target.checked) {
                      delete dienstleistungen.b;
                    }
                  }}
                />
                Baureinigung
             </Grid>
              <Grid item>
                <Checkbox
                  label="Fisch"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDienstleistungen({
                        ...dienstleistungen,
                        c: "Unterhaltsreinigung",
                      });
                    } else if (!e.target.checked) {
                      delete dienstleistungen.c;
                    }
                  }}
                />{" "}
                Unterhaltsreinigung
            </Grid>
              <Grid item>
                <Checkbox
                  label="Fisch"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDienstleistungen({
                        ...dienstleistungen,
                        d: "Fensterreinigung",
                      });
                    } else if (!e.target.checked) {
                      delete dienstleistungen.d;
                    }
                  }}
                />{" "}
                Fensterreinigung
              </Grid>
              <Grid item>
                <Checkbox
                  label="Fisch"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDienstleistungen({
                        ...dienstleistungen,
                        e: "Frühlingsputz",
                      });
                    } else if (!e.target.checked) {
                      delete dienstleistungen.e;
                    }
                  }}
                />{" "}
                Frühlingsputz
                </Grid>
                <Grid item>
                <Checkbox
                  label="Fisch"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDienstleistungen({
                        ...dienstleistungen,
                        f: "Winterdienst",
                      });
                    } else if (!e.target.checked) {
                      delete dienstleistungen.h;
                    }
                  }}
                />{" "}
                Winterdienst
            </Grid><Grid item>
            
                <Checkbox
                  label="Fisch"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setDienstleistungen({
                        ...dienstleistungen,
                        g: "Hauswartung",
                      });
                    } else if (!e.target.checked) {
                      delete dienstleistungen.g;
                    }
                  }}
                />{" "}
                Hauswartung</Grid></Grid></Grid>
                <Grid item xs={12} md={4}>
                  <Grid container direction='row' justify='space-around' alignItems='center' >
                    <Grid item xs={12}>
                    <Typography variant='h6'>
                      Objekttyp
                    </Typography>
                  </Grid>
                  
                
               
               
                  <FormControl component="fieldset">
      <RadioGroup aria-label="Objekttyp" name="Objekttyp"  onChange={(e)=>setObjekttyp(e.target.value)}>
        <FormControlLabel value="Wohnung" control={<Radio />} label="Wohnung" />
        <FormControlLabel value="Einfamilienhaus" control={<Radio />} label="Einfamilienhaus" />
        <FormControlLabel value="Mehrfamilienhaus" control={<Radio />} label="Mehrfamilienhaus" />
        <FormControlLabel value="Geschäftshaus" control={<Radio />} label="Geschäftshaus" />
      </RadioGroup>
    </FormControl>

                <Button
                variant='contained'
                color='primary'
                  onClick={(e) => {

                    e.preventDefault();
                    window.grecaptcha.ready(function() {
                      window.grecaptcha.execute('6LeYSKsaAAAAAONRB6d8ClyfsEnYUOfthEnTiVNv', {action: 'submit'}).then(function(token) {
                        handleClick(vorname,nachname,firmenname, adresse, plzort, tel,email,nachricht,JSON.stringify(dienstleistungen),objekttyp);
                      });
                    });
                   
                    e.preventDefault();
                    

                  }}
                >
                  Anfrage Abschicken
                </Button>
              </Grid>
            </Grid>
            </Grid>

  );
}
